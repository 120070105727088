
.u-relative {
    position: relative;
}
.u-absolute {
    position: absolute;
}


.u-container {
    max-width: 90%;
    margin: 0 auto;
}

@media (min-width: 768px) {
    .u-container {
        max-width: 70%;
    }
}

@media (min-width: 992px) {
    .u-container {
        max-width: 50%;
    }
}






.Prime-Header {

    background-color: black;
    margin-bottom: 80px;
    
    &-img {
        max-height: 400px;
        width: 100%;
        object-fit: cover;
        opacity: 0.5;
    }

    &-brand {

        bottom: 0px;
        left: 0px;
        right: 0px;

        display: flex;
        justify-content: flex-start;
        align-items: center;

        padding: 15px;


        & img {
            width: 15%;
            object-fit: cover;
            margin-right: 20px;
        }

        & h1 {
            color: white;
            font-size: 1.2rem;
            font-weight: 900;

        }

        @media (min-width: 576px) {
            & h1  {
                font-size: 2rem;
            }
        }

        @media (min-width: 992px) {
            & h1  {
                font-size: 3.2rem;
            }
        }

    }

}


.Prime-Checks {

    & ul {
        margin-bottom: 40px;
    }

    & li {
        margin-bottom: 20px;
        font-size: 1rem;
        max-width: 80ch;
        display: flex;
        align-items: flex-start;

    }


    & svg {
        min-width: 20px;
        min-height: 20px;
        color: green;
        margin-right: 10px;
    }

    @media (min-width: 576px) {
        & li {

            font-size: 1.2rem;
        }
    
        & svg {
            min-width: 25px;
            min-height: 25px;
        }
    }


}

.Prime-TermsConditions {

    text-align: center;
    margin-bottom: 40px;

    & p {
        margin: 0px;
        font-size: 1rem;
    }

    & a {
        font-size: 1.2rem;
        font-weight: 800;
    }

    @media (min-width: 576px) {
        & p {

            font-size: 1.2rem;
            
        }
        & a {
            font-size: 1.4rem;
            font-weight: 800;
        }
    }

}

.Prime-Button {
    background-color: green;
    color: white!important;
    padding: 10px 20px;
    border-radius: 12px;
}

.Prime-Subscribe {
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    & p {
        font-size: 1.2rem!important;
        font-weight: 800;

        margin: 0px;

        margin-right: 10px;

        & span {
            font-size: 1rem;
            color: gray;
        }
    }
}

.Prime-Known {

    & h2 {
        font-size: 1.4rem;
    }

    & h3 {
        font-size: 1.2rem;
    }

    & p {
        font-size: 1rem;
        max-width: 80ch;
    }

    @media (min-width: 576px) {
        & h2 {

            font-size: 1.8rem;
        }
    
        & h3 {
            font-size: 1.4rem;
        }

        & p {
            font-size: 1.2rem;
        }
    }   

    &-item {

        margin-bottom: 40px;
        & div {
           display: flex;
           justify-content: center;
        }

    }
}

