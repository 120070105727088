// All Css Here
@import '~bootstrap/dist/css/bootstrap.min.css';
@import "../public/assets/css/plugins.css";
@import "../public/assets/scss/style";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@import 'node_modules/react-modal-video/scss/modal-video.scss';

h1, h2, h3, h3, h4, p, input, label {
    font-family: Montserrat, sans-serif;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}