html,
body {
  height: auto;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: Poppins, sans-serif !important;
}

ul,
ol {
  list-style: none;
}

a:visited,
a:link,
a:focus,
a:hover,
a:active,
a {
  text-decoration: none;
  list-style: none;
}

.whywe--item .icon {
  font-size: 4rem;
  width: auto;
  color: #ff2d6d;
}

/* LOGIN TUTORES */
.login {
  display: grid;
  height: 100vh;
  grid-template-rows: 15% 42% 43%;
  grid-template-columns: 50% 50%;
}

.movil__header {
  grid-row: 1 / 2;
  grid-column: 1 / 3;
  display: grid;
  overflow: hidden;
  height: 100%;
  grid-template-columns: repeat(2, 1fr);
}

.movil__volver {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: 20% 80%;
}

.movil__volver--icon {
  margin-left: 1rem;
  font-size: 2rem;
  cursor: pointer;
}

.movil__volver h3 {
  margin-left: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.movil__img {
  display: grid;
  align-items: center;
  justify-content: end;
  padding-right: 1rem;
}

.movil__img img {
  margin-top: 0.3rem;
  height: 100px;
}

.login__img {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.login__img img {
  margin: 0;
  height: 100%;
  width: 100%;
  padding: 0.5rem 1rem 1rem 1rem;
}

.login__form {
  grid-row: 3 / 4;
  grid-column: 1 / 3;
  height: 100%;
  width: 100%;
  display: block;
}

.login__img div {
  display: none;
  visibility: hidden;
}

.login__form .login__form--img {
  display: none;
}

.login__form .login__form--container {
  display: grid;
  height: 100%;
  grid-template-rows: 20% 27% 27% 26%;
  width: 100%;
}

.login__form--container h3 {
  display: grid;
  margin: 0;
  width: 100%;
  font-size: 2rem;
  color: black;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  align-items: center;
  justify-content: center;
}

.login__form--input {
  height: 100%;
  width: 100%;
  margin: 0;
  display: grid;
  padding: 0 2rem;
}

.login__form--input label {
  display: grid;
  height: 100%;
  font-size: 1.4rem;
  font-weight: bold;
  align-items: center;
  margin: 0;
}

.login__form--input input {
  width: 100%;
  margin: auto;
}

.login__form button {
  background-color: #ffbe00;
  color: white;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.2rem;
  margin: 1.1rem 4rem 1.3rem 4rem;
  cursor: pointer;
  border-radius: 1rem;
}

@media only screen and (min-width: 650px) {
  .movil__volver {
    margin-top: 3rem;
    grid-template-columns: 15% 85%;
  }
  .login__img img {
    padding: 0 2.5rem 2rem 2.5rem;
  }
  .login__form--input {
    padding: 0 3.5rem;
  }
  .login__form button {
    margin: 1.1rem 6rem 1.3rem 6rem;
  }
}

@media only screen and (min-width: 850px) {
  .movil__volver {
    margin-top: 3rem;
    grid-template-columns: 18% 82%;
  }
  .movil__volver--icon {
    margin-left: 2rem;
    font-size: 2.5rem;
  }
  .movil__img {
    padding-right: 2rem;
  }
  .login__img img {
    padding: 0 5rem 1rem 5rem;
  }
  .login__form--input {
    padding: 0 8rem;
  }
  .login__form button {
    margin: 1.1rem 9rem 1.3rem 9rem;
  }
}

@media only screen and (min-width: 1150px) {
  .movil__header {
    visibility: hidden;
    display: none;
  }
  .login {
    grid-template-rows: 1fr;
    grid-template-columns: repeat(2, 1fr);
  }

  .login__img {
    grid-column: 1/2;
    grid-row: 1/2;
    display: grid;
    overflow: hidden;
  }

  .login__img div {
    visibility: visible;
    display: grid;
    grid-template-columns: 18% 82%;
  }

  .login__img div h3 {
    grid-column: 2/3;
    font-size: 2rem;
    margin-top: 1.6rem;
  }

  .login__img--icon {
    display: grid;
    grid-column: 1/2;
    margin-top: 1.6rem;
    margin-left: 4.2rem;
    font-size: 3rem;
    cursor: pointer;
  }

  .login__img img {
    height: 95%;
    padding: 0 2rem 2rem 2rem;
  }

  .login__form {
    grid-column: 2/3;
    grid-row: 1/2;
    display: grid;
    grid-template-rows: 18% 82%;
  }

  .login__form .login__form--img {
    display: grid;
    align-items: center;
    justify-content: start;
    padding-left: 1rem;
  }

  .login__form .login__form--img img {
    height: 100px;
  }

  .login__form--container h3 {
    font-size: 2.5rem;
  }

  .login__form--input {
    display: grid;
    gap: 0.8rem;
    grid-template-rows: 30% 70%;
  }

  .login__form--input label {
    font-size: 1.7rem;
  }

  .login__form--input input {
    margin: 0;
  }

  .login__form--container {
    padding-bottom: 2rem;
  }
}

/* FIN LOGIN TUTORES */

.agendado__container {
  height: auto;
}

.agendado__container--header {
  height: 150px;
  display: grid;
  align-items: center;
  justify-content: center;
}

.agendado__container--header img {
  height: 110px;
  align-items: center;
}

.agendado__container--title {
  margin-top: 4rem;
  padding: 0 2rem;
}

.agendado__container--title h4 {
  line-height: 35px;
  font-size: 1.5rem;
  color: black;
  margin: 0;
  padding: 0;
  font-weight: bold;
  letter-spacing: 0.15rem;
}

.agendado__container--title .title__color {
  color: #f9004d;
}

.agendado__container--parrafo {
  display: grid;
  align-items: center;
  height: 130px;
  padding: 0 2rem;
}

.agendado__container--parrafo .parrafo--item {
  font-size: 16px;
  height: auto;
  font-weight: 400;
  line-height: 30px;
  margin: 0;
}

.agendado__button {
  display: grid;
  padding: 4rem 4rem 0rem 4rem;
}

.agendado__button button {
  padding: 0.7rem 1.3rem;
  background-color: #ffbd00;
  cursor: pointer;
  font-size: 1.5rem;
  letter-spacing: 0.2rem;
  font-weight: bold;
  color: white;
  border: none;
}

.importante__title {
  height: 130px;
  display: grid;
  align-items: center;
  justify-content: center;
}

.importante__title h2 {
  margin: 0;
  font-size: 2.5rem;
  letter-spacing: 0.1rem;
  color: black;
  font-weight: 100;
  letter-spacing: 0.2rem;
  font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;
}

.reglamentos {
  display: grid;
  height: 290px;
  padding: 0 1.5rem;
}

.reglamentos--short {
  display: grid;
  height: 240px;
  padding: 0 1.5rem;
}

.reglamentos--short h3 {
  border-radius: 1rem;
  cursor: pointer;
  display: grid;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: white;
}

.reglamentos h3 {
  border-radius: 1rem;
  cursor: pointer;
  display: grid;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  color: white;
}

.reglamentos--item1 {
  background-color: #afafaf;
}

.reglamentos--item2 {
  background-color: #ffd97b;
}

.reglamentos--item3 {
  background-color: #f26497;
}

.reglamentos--item4 {
  background-color: #aec4c1;
}

.reglamentos--item1:hover {
  border-top: 2px solid #4a5b79;
}

.reglamentos--item2:hover {
  border-bottom: 1px solid #2a67ba;
}

.reglamentos--item3:hover {
  border-top: 1px solid #25232e;
}

.reglamentos--item4:hover {
  border-top: 2px solid #4a5b79;
}

/* .agendado__button--aceptar {
    height: 120px;
    display: grid;
    padding: 2.1rem 8.4rem;
}   

.agendado__button--aceptar button {
    display: block;
    padding: 0.7rem 1.3rem;
    background-color: #ffbd00;
    cursor: pointer;
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
    font-weight: bold;
    color: white;
    border: none;
} */

.agendado__button--aceptar {
  display: flex;
  height: 80px;
  padding: 0 3rem 1rem 3rem;
  align-items: center;
  justify-content: center;
}

.agendado__button--aceptar button {
  padding: 0.6rem 2.1rem;
  background-color: #ffbd00;
  cursor: pointer;
  font-size: 1.3rem;
  letter-spacing: 0.2rem;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 15px;
}

.dudas {
  height: 60px;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
}

.dudas h4 {
  font-size: 1.05rem;
  font-weight: 100;
  margin: 0;
}

.dudas h4 strong {
  color: #f26497;
}

.parrafo__confirm {
  height: 120px;
  display: grid;
  align-items: center;
  padding: 0 2rem;
  text-align: justify;
}

.parrafo__confirm h4 {
  color: black;
  line-height: 27px;
  margin: 0;
  font-size: 1rem;
  font-weight: bold;
}

.politica {
  height: 70px;
  display: grid;
  align-items: center;
  justify-content: center;
  margin-bottom: 6rem;
  text-align: center;
}

.politica h3 {
  font-weight: 50;
  margin: 0;
  font-size: 1rem;
}

.politica h3 strong {
  color: #f26497;
}

.reglamento__title,
.instrucciones__title {
  height: auto;
  display: grid;
  align-items: center;
  justify-content: center;
}

.reglamento__title h2,
.instrucciones__title h2 {
  margin: 0;
  color: black;
}

.materiales__title {
  padding: 0 3rem;
  height: 140px;
  display: grid;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.materiales__title h2 {
  line-height: 50px;
  margin: 0;
  color: black;
}

.pdf--container {
  margin: 1rem 2rem;
  border-radius: 1rem;
  padding-bottom: 1rem;
  background-color: #e3e3e3;
}

.arrow--container {
  display: block;
  z-index: 1;
  width: 70px;
  margin: 1rem 0 0 1.5rem;
}

.arrow {
  cursor: pointer;
  font-size: 2.5rem;
}

.up--arrow {
  border-radius: 2rem;
  font-size: 3rem;
  background-color: #ffbc00;
  padding: 1rem;
  cursor: pointer;
}

@media only screen and (min-width: 650px) {
  .agendado__container--header {
    height: 170px;
  }
  .agendado__container--header img {
    height: 130px;
  }
  .agendado__container--title {
    margin-top: 5rem;
    padding: 0 4rem;
    margin-bottom: 5rem;
  }
  .agendado__container--title h4 {
    line-height: 52px;
    font-size: 2.9rem;
    letter-spacing: 0.2rem;
  }
  .agendado__container--parrafo {
    height: 160px;
    padding: 0 4rem;
  }
  .agendado__container--parrafo .parrafo--item {
    text-align: justify;
    font-size: 1.3rem;
    line-height: 35px;
  }
  .agendado__button button {
    padding: 0.9rem 2rem;
    font-size: 1.6rem;
  }
  .importante__title {
    height: 140px;
  }
  .importante__title h2 {
    font-size: 2.8rem;
  }
  .reglamentos h3 {
    font-size: 1.3rem;
  }
  .reglamentos--short h3 {
    font-size: 1.3rem;
  }
  .dudas {
    height: 90px;
  }
  .dudas h4 {
    font-size: 1.4rem;
  }
  .parrafo__confirm {
    padding: 0 4rem;
  }
  .parrafo__confirm h4 {
    line-height: 29px;
    font-size: 1.3rem;
  }
  .reglamentos {
    padding: 0 2rem;
  }
  .agendado__button--aceptar button {
    padding: 1rem 3rem;
  }
  .politica h3 {
    font-size: 1.3rem;
  }
  .pdf--container {
    margin: 1rem 4rem;
  }
}
@media only screen and (min-width: 850px) {
  .agendado__container--header {
    height: 190px;
  }
  .agendado__container--header img {
    height: 150px;
  }
  .agendado__container--title {
    margin-top: 7rem;
    padding: 0 5rem;
    margin-bottom: 7rem;
  }
  .agendado__container--title h4 {
    line-height: 70px;
    font-size: 4rem;
    letter-spacing: 0.2rem;
  }
  .agendado__container--parrafo {
    padding: 0 5rem;
  }
  .agendado__container--parrafo .parrafo--item {
    font-size: 1.5rem;
    line-height: 38px;
  }
  .agendado__button--aceptar button {
    padding: 1rem 3.3rem;
  }
  .importante__title {
    height: 180px;
  }
  .importante__title h2 {
    font-size: 4.1rem;
  }
  .reglamentos {
    height: 350px;
    padding: 0 3rem;
  }
  .reglamentos--short {
    height: 260px;
    padding: 0 3rem;
  }
  .reglamentos h3 {
    font-size: 1.7rem;
  }
  .reglamentos--short h3 {
    font-size: 1.7rem;
  }
  .dudas {
    height: 100px;
  }
  .dudas h4 {
    font-size: 1.6rem;
  }
  .parrafo__confirm {
    padding: 0 6rem;
  }
  .parrafo__confirm h4 {
    line-height: 32px;
    font-size: 1.5rem;
  }
  .agendado__button--aceptar {
    padding: 1.5rem 19rem;
  }
  .politica h3 {
    font-size: 1.5rem;
  }
  .pdf--container {
    margin: 1rem 6rem;
  }
}
@media only screen and (min-width: 1150px) {
  .agendado__container--header {
    height: 210px;
  }
  .agendado__container--header img {
    height: 170px;
  }
  .agendado__container--title {
    margin-top: 3rem;
    padding: 0 10rem;
    margin-bottom: 7rem;
  }
  .agendado__container--title h4 {
    line-height: 100px;
    font-size: 5rem;
    letter-spacing: 0.4rem;
  }
  .agendado__container--parrafo {
    padding: 0 10rem;
  }
  .agendado__container--parrafo .parrafo--item {
    font-size: 1.7rem;
    line-height: 50px;
  }
  .agendado__button--aceptar button {
    padding: 1rem 3.7rem;
  }
  .agendado__button button {
    font-size: 2.2rem;
  }
  .importante__title {
    margin-bottom: 3rem;
  }
  .importante__title h2 {
    font-size: 5rem;
  }
  .reglamentos {
    padding: 0 10rem;
    height: 400px;
  }
  .reglamentos--short {
    padding: 0 10rem;
    height: 280px;
  }
  .reglamentos h3 {
    font-size: 2.5rem;
    letter-spacing: 0.1rem;
  }
  .reglamentos--short h3 {
    font-size: 2.5rem;
    letter-spacing: 0.1rem;
  }
  .dudas {
    height: 110px;
  }
  .dudas h4 {
    font-size: 1.8rem;
  }
  .parrafo__confirm {
    height: 180px;
    padding: 0 10rem;
    text-align: center;
  }
  .parrafo__confirm h4 {
    line-height: 50px;
    font-size: 1.7rem;
  }
  .agendado__button--aceptar {
    padding: 1.5rem 26rem;
  }
  .agendado__button--aceptar button {
    font-size: 2.2rem;
  }
  .politica {
    margin-top: 2rem;
    margin-bottom: 13rem;
  }
  .politica h3 {
    font-size: 1.8rem;
  }
  .reglamento__title h2,
  .instrucciones__title h2 {
    font-size: 5rem;
  }
  .materiales__title h2 {
    font-size: 5rem;
  }
  .pdf--container {
    margin: 1rem 8rem;
    margin-bottom: 10rem;
  }
}

/* GARANTIA PAGE */

.garantia__button--aceptar {
  display: flex;
  height: 100px;
  padding: 0 2rem 1rem 2rem;
  align-items: center;
  justify-content: center;
}

.garantia__button--aceptar button {
  padding: 1.2rem 1rem;
  background-color: #ffbd00;
  cursor: pointer;
  font-size: 1.3rem;
  letter-spacing: 0.1em;
  text-align: center;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 15px;
}

@media only screen and (min-width: 650px) {
  .garantia__button--aceptar {
    height: 150px;
  }
  .garantia__button--aceptar button {
    padding: 1.2rem 3rem;
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 850px) {
  .garantia__button--aceptar {
    height: 170px;
  }
  .garantia__button--aceptar button {
    padding: 1.8rem 3rem;
    font-size: 1.9rem;
  }
}

@media only screen and (min-width: 1150px) {
  .garantia__button--aceptar {
    height: 170px;
  }
  .garantia__button--aceptar button {
    padding: 1.8rem 3rem;
    font-size: 1.9rem;
  }
}

/*DESIGNER PORTAFOLIO*/
.designer--btn--container {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Poppins, sans-serif !important;
}

.designer--btn {
  border-radius: 0.5rem;
  padding: 0.5rem 2rem;
  color: white;
  font-size: 1.3rem;
  background-color: #f9004d;
  font-family: Poppins, sans-serif !important;
  font-weight: bold;
}

.hash--btn {
  font-family: Poppins, sans-serif !important;
}

.main-content {
  margin-top: 107.42px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, 1fr);
}

.main-content-tittle {
  grid-column: 1 / 2;
  grid-row: 1 / 3;
}

.main-content-text {
  grid-column: 1 /2;
  grid-row: 3 / 4;
}

.main-content-button {
  grid-column: 1 / 2;
  grid-row: 4 / 5;
}

.main-content-img {
  grid-column: 2/3;
  grid-row: 1/5;
  object-fit: contain;
}

.good_font {
  font-family: Poppins, sans-serif !important;
}

/* .border-btn {
  border-right: #ecb929 4px solid;
  border-radius: 400px;
  padding: 0px 2px;
} */

#smart-tittle {
  padding-top: 15px;
}

#smart-main-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

@media screen and (max-width: 766px) {
  #smart-tittle {
    padding: 30px 15px;
  }
  #smart-main-container {
    display: block;
  }
}

.smart-end {
  display: grid;
  grid-template-rows: 3fr 1fr;
  place-items: center;
  margin-bottom: 20px;
}

.smart-end-tittle {
  margin: 20px 0px;
}

.smart-end-text {
  padding-bottom: 20px;
  font-size: 25px;
}

@media screen and (max-width: 715px) {
  .smart-end {
    display: grid;
    grid-template-rows: 2fr 1fr;
    place-items: center;
    margin-top: 10px;
    margin-bottom: 20px;
    text-align: center;
    padding: 0px 20px;
  }

  .smart-end-tittle {
    font-size: 35px;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .smart-end-text {
    padding-bottom: 20px;
    font-size: 20px;
  }
}

.smart-footer-content {
  background-color: #060403;
  display: grid;
  grid-template-columns: 1fr 1fr;
  color: #e3e3e3;
  padding: 40px 180px;
  gap: 150px;
}

@media screen and (max-width: 715px) {
  .smart-footer-content {
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 40px 50px;
  }
}

.smart-footer-info {
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
}

.smart-footer-logo {
  height: 75px;
  /* width: 200px; */
  grid-column: 1/3;
  object-fit: contain;
  margin-bottom: 15px;
  justify-self: start;
}

.smart-footer-tag {
  grid-column: 1/3;
  margin-bottom: 10px;
}
.smart-footer-phone {
  grid-column: 1/3;
}
.smart-footer-email {
  grid-column: 1/3;
}

.smart-footer-btn {
  height: 30px;
  width: 100%;
  background-color: #ffbc00;
}

.smart-footer-input {
  position: relative;
  box-sizing: border-box;
  color: white;
  padding: 15px 0px 15px 35px;
  width: 70%;
}

@media screen and (max-width: 991px) {
  .smart-footer-input {
    width: 100%;
  }
}

.smart-footer-btn-input {
  color: white;
  position: absolute;
  width: 20px;
  height: 20px;
  left: unset;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.smart-solid {
  border-top: 1px solid #bbb;
  width: 50%;
}

.smart-services {
  padding-top: 30px;
  background-color: #000107;
}

#smart-main-content {
  background-color: black;
}

.img-main-smart {
  padding-left: "90px";
}

.floating_wa_button {
  position: fixed !important;
  z-index: 2;
  bottom: 0;
  right: 0;
  margin-right: 6rem !important;
  margin-bottom: 3rem !important;
}

.floating_wa_button_Mobile {
  position: fixed !important;
  z-index: 2;
  bottom: 0;
  right: 0;
  margin-right: 1.5rem !important;
  margin-bottom: 8rem !important;
}
